import { create } from "zustand";
import http from "../utils/axios";
import { useShopStore, useExerciseStore } from "./";
import moment from "moment";

const useUserStore = create((set) => ({
  user: {},
  valid: false,
  currentSubscription: {},
  subscriptionOptions: [],

  setUser: (user) => set({ user }),
  getMe: async () => {
    try {
      const res = await http.get("/me");
      if (res.error === false) {
        set({ user: res.item });

        let isValid = false;
        let currentSubscription = "";
        res.item?.subscriptionHistory?.forEach((obj) => {
          if (moment(obj.end).isAfter(moment())) {
            isValid = true;
            currentSubscription = obj;
          }
        });

        set({ valid: isValid, currentSubscription });
      }
    } catch (error) {
      console.log(error);
    }
  },
  getSubscriptionOptions: async () => {
    try {
      const res = await http.get("/subscription?sort_by=order&order_by=asc");
      if (res.error === false) {
        set({ subscriptionOptions: res.data });
      }
    } catch (error) {
      console.log(error);
    }
  },
  logout: () => {
    useExerciseStore.getState().clearAll();
    useShopStore.getState().clearAll();
    set({ user: {} });
  },
}));

export default useUserStore;
