import { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import http from "../../utils/axios";
import { useParams } from "react-router-dom";
import PageTitle from "./PageTitle";
import useToast from "../../utils/toast";
import { Divider, Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useUserStore } from "../../stores";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Payment = () => {
  const { t } = useTranslation("common");
  const location = useLocation();
  const isSubscription = location.pathname.includes("subscription");

  const toast = useToast();
  const { productId } = useParams();

  const [clientSecret, setClientSecret] = useState(null);
  const [product, setProduct] = useState();
  const [paymentIntent, setPaymentIntent] = useState();
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const userStore = useUserStore();

  const getProduct = async () => {
    try {
      let res;
      if (isSubscription) {
        res = await http.get(`/subscription/${productId}`);
      } else {
        res = await http.get(`/coin/${productId}`);
      }

      if (res.error === false) {
        setProduct(res.item);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const subscribeFunc = async (id) => {
    try {
      const res = await http.post("payment/stripe/subscription", {
        subscription: id,
      });
      setClientSecret(res.clientSecret);
    } catch (error) {
      console.log("err", error);
      setError(error?.data?.errors[0]?.message);
    }
  };

  const topupFunc = async (id) => {
    try {
      const res = await http.post("payment/stripe/payment", {
        coinId: id,
      });
      setClientSecret(res.clientSecret);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const cardElement = elements.getElement(CardElement);
    const { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: userStore.user.name,
          },
        },
      },
    );

    if (error) {
      toast.error(error.message);
    } else {
      toast.success("Payment success");
      setPaymentIntent(paymentIntent);
      // this might not work, as time is needed for webhook to update user
      userStore.getMe();
    }

    setIsSubmitting(false);
  };

  useEffect(() => {
    getProduct().then(() => {
      if (isSubscription) {
        subscribeFunc(productId);
      } else {
        topupFunc(productId);
      }
    });
  }, []);

  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    return "";
  }

  if (paymentIntent && paymentIntent.status === "succeeded") {
    return <PageTitle title={t("checkout.succeeded")} />;
  }

  return (
    <div>
      <PageTitle title={t("checkout.title")} />

      {error && <div>{error}</div>}
      {clientSecret && (
        <form onSubmit={handleSubmit}>
          <div className="flex flex-row">
            <div className="bg-white py-16 px-8 lg:px-40 w-full rounded-lg shadow-md">
              <div className="text-lg font-semibold">
                {t("checkout.summery")}
              </div>
              <Divider />
              <div className="flex justify-between py-5">
                <div>
                  {product.name}{" "}
                  {product?.onceOnly && (
                    <div className="font-light text-sm whitespace-nowrap pt-2">
                      * {t("sub.once")}
                      <Link
                        to={`${process.env.PUBLIC_URL}/disclaimer_trial_plan.pdf`}
                      >
                        {t("sub.once-disclaimer")}
                      </Link>
                      {t("sub.once-suffix")}
                    </div>
                  )}
                </div>
                <div>{product.price}</div>
              </div>

              <CardElement
                options={{ hidePostalCode: true }}
                className="border-solid border-2 border-gray-300 rounded-md p-4"
              />

              <Button
                type="submit"
                variant="contained"
                className="w-full mt-20"
                disabled={isSubmitting}
              >
                {t("checkout.submit")}
              </Button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default Payment;
