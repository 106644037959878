import { useEffect, useState } from "react";
import PageTitle from "../components/common/PageTitle";
import UploadPdf from "../components/exercise/UploadPdf";
import { useShopStore, useUserStore } from "../stores";
import NoSubscription from "./common/NoSubscription";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { VideoModal } from "../components/common/VideoModal";
import { Button } from "@mui/material";
import { PlayCircle } from "@mui/icons-material";

const Exercise = () => {
  const { t } = useTranslation("common");
  const location = useLocation();
  const shopStore = useShopStore((state) => state);
  const userStore = useUserStore();
  const [canView, setCanView] = useState(true);
  const [open, setOpen] = useState(false);

  const isGrammar = location.pathname.includes("grammar");

  useEffect(() => {
    if (!shopStore.fetched) {
      shopStore.getAll();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (
      userStore?.user?.role === "admin" ||
      userStore?.user?.role === "teacher" ||
      userStore?.user?.bypass
    ) {
      setCanView(true);
    } else {
      // if subscription end is less than today
      if (!userStore?.valid) {
        setCanView(false);
      } else {
        setCanView(true);
      }
    }
  }, [
    // userStore?.user?.subscription?.end,
    userStore?.user?.role,
    userStore?.valid,
  ]);

  return (
    <div>
      {isGrammar && (
        <VideoModal
          isOpen={open}
          onClose={() => setOpen(false)}
          videoId="SDsxneoiEko"
        />
      )}
      <NoSubscription visible={!canView} />

      <PageTitle title={isGrammar ? t("header.grammar") : t("header.reading")}>
        {isGrammar && (
          <Button
            onClick={() => setOpen(true)}
            className="text-2xl md:text-4xl pt-0"
          >
            <PlayCircle sx={{ fontSize: 36 }} /> {t("header.tutorial-clip")}
          </Button>
        )}
      </PageTitle>

      <UploadPdf type={isGrammar ? "grammar" : "comprehension"} />
    </div>
  );
};

export default Exercise;
