import { useEffect, useRef, useState } from "react";
import PageTitle from "../../components/common/PageTitle";
import SubscriptionBox from "../../components/subscription/SubscriptionBox";
import http from "../../utils/axios";
import { useUserStore } from "../../stores/";
import { useNavigate } from "react-router-dom";
import useToast from "../../utils/toast";
import { apiErrorMessage } from "../../utils/helpers";
import Modal from "../../components/common/Modal";
import { Box, Button, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useStore } from "zustand";

const Subscription = () => {
  const { t } = useTranslation("common");
  const modalRef = useRef();
  const navigate = useNavigate();
  const toast = useToast();
  const userStore = useUserStore();

  const [isLoading, setIsLoading] = useState(false);
  const [newSubscription, setNewSubscription] = useState("");
  const subscriptionHistory = userStore.user?.subscriptionHistory || [];

  const subscribeOnClick = (id) => {
    let currentPlan;

    // if (!userStore.user?.subscription?.start) {
    //   currentPlan = false;
    // } else {
    //   currentPlan = userStore.subscriptionOptions?.find(
    //     (item) => item._id === userStore.user?.subscription?.subscription,
    //   );
    // }

    if (userStore.currentSubscription !== "") {
      currentPlan = userStore.subscriptionOptions?.find(
        (item) => item._id === userStore.currentSubscription.subscription,
      );
    } else {
      currentPlan = false;
    }

    console.log("currentPlan", currentPlan);

    if (!currentPlan || currentPlan?.onceOnly) {
      navigate(`/subscription/${id}/payment`);
    } else {
      setNewSubscription(id);
      modalRef.current.handleOpen();
    }
  };

  const cancelSubscription = async (id) => {
    try {
      const res = await http.post("/payment/stripe/subscription/cancel", {
        subscription: id,
      });
    } catch (error) {
      toast.error(apiErrorMessage(error) || "Unknown Error");
    } finally {
      userStore.getMe();
    }
  };

  const resumeSubscription = async (id) => {
    try {
      const res = await http.post("/payment/stripe/subscription/resume", {
        subscription: id,
      });
    } catch (error) {
      toast.error(apiErrorMessage(error) || "Unknown Error");
    } finally {
      userStore.getMe();
    }
  };

  const updateSubscription = async () => {
    try {
      setIsLoading(true);
      const res = await http.post("/payment/stripe/subscription/update", {
        subscription: newSubscription,
      });

      console.log(res);
    } catch (error) {
      toast.error(apiErrorMessage(error) || "Unknown Error");
    } finally {
      userStore.getMe();
      setIsLoading(false);
      modalRef.current.handleClose();
    }
  };

  useEffect(() => {
    if (userStore.subscriptionOptions.length === 0) {
      userStore.getSubscriptionOptions();
    }
  }, []); // eslint-disable-line

  return (
    <div>
      <PageTitle title={t("sub.title")} />
      <div className="flex flex-col gap-6">
        {userStore.subscriptionOptions?.map((item, index) => (
          <SubscriptionBox
            key={index}
            item={item}
            currentPlan={
              item._id === userStore?.currentSubscription?.subscription
            }
            expiredDate={userStore?.currentSubscription?.end}
            subscribeOnClick={subscribeOnClick}
            cancelSubscription={cancelSubscription}
            resumeSubscription={resumeSubscription}
            subscriptionActive={userStore.valid}
            disableSubscribe={
              (item.onceOnly &&
                subscriptionHistory.some((i) => i.subscription === item._id)) ||
              (item.onceOnly &&
                !!userStore?.currentSubscription?.subscription?.start)
            }
            t={t}
          />
        ))}
      </div>
      <Modal ref={modalRef}>
        <Box>
          <Box sx={{ marginTop: 2, marginBottom: 4 }}>
            {t("sub.update-sure")}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              gap: 2,
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                modalRef.current.handleClose();
              }}
            >
              {t("cart.cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                updateSubscription();
              }}
              disabled={isLoading}
            >
              {t("cart.submit")}
              {isLoading && <CircularProgress className="absolute" size={20} />}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Subscription;
